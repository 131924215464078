import React, { useState, useRef } from "react"

// Libraries
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Styles
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"

// Icons
import IconBelly from "assets/icons/home/testimonials/icon-belly.inline.svg"

const StyledTestimonials = styled.section`
  position: relative;
  padding: 60px 0 0 0;
  overflow: visible !important;

  ${breakpoint.medium`
    padding: 86px 0 100px 0;
  `}

  .testimonials__belly {
    max-width: 100vw;
    position: absolute;
    right: 0;
    bottom: calc(100% - 8px);
    left: 0;
    margin: auto;
  }

  ${Container} {
    overflow: hidden;
  }

  .testimonials__carousel {
    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        > div {
          height: 100%;
        }
      }

      .testimony {
        height: 100%;
        display: flex !important;
        justify-content: center;
        flex-wrap: wrap;

        > div {
          width: 100%;
          flex-shrink: 0;
        }

        p {
          white-space: normal;
        }
      }
    }
  }

  .carousel__controls {
    max-width: 384px;
    margin-left: auto;
    margin-right: auto;

    .controls__pictures {
      height: 96px;
    }

    .bullet {
      width: 64px;
      height: 64px;
      padding: 4px;
      margin-right: 16px;
      border-radius: 50%;
      border: 2px solid ${colors.silver__light};
      opacity: 0.6;
      transition: all 0.3s ease;

      &:last-child {
        margin-right: 0;
      }

      ${breakpoint.medium`
          margin-right: 32px;
        `}

      &:hover {
        opacity: 1;
      }

      &.active {
        width: 96px;
        height: 96px;
        opacity: 1;
        border-color: ${colors.turquoise__primary};
      }
    }
  }

  .testimonials__logos {
    margin-top: 56px;

    ${breakpoint.medium`
      margin-top: 72px;
    `}

    .logo {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 64px;

      ${breakpoint.medium`
        aspect-ratio: 1;
        margin-bottom: 0;
      `}
    }
  }
`

const Testimo = () => {
  const ref = useRef()
  const [activeIndex, setActiveIndex] = useState(0)

  const carouselSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    fade: true,
    arrows: false,
    beforeChange: (oldIndex, newIndex) => setActiveIndex(newIndex),
  }

  const goTo = (index) => {
    if (ref.current) {
      ref.current.slickGoTo(index)
    }
  }

  const {
    image1,
    image2,
    image3,
    image4,
    multicare,
    heritage,
    brightview,
    maryland,
    flynn,
    arbor,
    integracare,
  } = useStaticQuery(graphql`
    query {
      multicare: file(
        relativePath: { eq: "home/testimonials/logo-multicare.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 182, quality: 100)
        }
      }

      heritage: file(
        relativePath: { eq: "home/testimonials/logo-heritage.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 140, quality: 100)
        }
      }

      brightview: file(
        relativePath: { eq: "home/testimonials/logo-brightview.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 180, quality: 100)
        }
      }

      maryland: file(
        relativePath: { eq: "home/testimonials/logo-maryland.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 205, quality: 100)
        }
      }

      flynn: file(relativePath: { eq: "home/testimonials/logo-flynn.png" }) {
        childImageSharp {
          gatsbyImageData(width: 179, quality: 100)
        }
      }

      arbor: file(relativePath: { eq: "home/testimonials/logo-arbor.png" }) {
        childImageSharp {
          gatsbyImageData(width: 183, quality: 100)
        }
      }

      integracare: file(
        relativePath: { eq: "home/testimonials/logo-integracare.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 183, quality: 100)
        }
      }

      image1: file(
        relativePath: { eq: "home/testimonials/testimonials-image-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 96, quality: 100)
        }
      }

      image2: file(
        relativePath: { eq: "home/testimonials/testimonials-image-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 96, quality: 100)
        }
      }

      image3: file(
        relativePath: { eq: "home/testimonials/testimonials-image-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 96, quality: 100)
        }
      }

      image4: file(
        relativePath: { eq: "home/testimonials/testimonials-image-4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 96, quality: 100)
        }
      }
    }
  `)

  const testimonials = [
    {
      company: "MultiCare Healthcare System",
      logo: multicare,
      quote:
        "“Through our partnership with Arena, we grew our applicant pool of Registered Nurses by more than 20%.”",
      author: {
        name: "June M. Altaras, MN, NEA-BC, RN",
        role: "SVP and Chief Quality, Safety and Nursing Officer",
        profilePicture: image1,
      },
    },
    {
      company: "Heritage Senior Living",
      logo: heritage,
      quote:
        "“Arena helps us reduce new hire turnover and stabilize our staff, improving our bottom line and generating a sustainable competitive advantage.”",
      author: {
        name: "Greg Bobka",
        role: " Chief Financial Officer",
        profilePicture: image2,
      },
    },
    {
      company: "Brightview Senior Living",
      logo: brightview,
      quote:
        "“There’s no other technology that I have  seen that reduces turnover. Some tools can get you more people, but there’s nothing that told us whether they would stay.”",
      author: {
        name: "Vicki White",
        role: "Director of Employee Relations",
        profilePicture: image3,
      },
    },
    {
      company: "IntegraCare Corporation",
      logo: integracare,
      quote:
        "“We are grateful for and value the additional applicant flow Talent Discovery brings to our communities.”",
      author: {
        name: "Brittany Balouris",
        role: "Director of Team Member Experience & Engagement",
        profilePicture: image4,
      },
    },
  ]

  const logos = [multicare, arbor, flynn, brightview, maryland]

  return (
    <StyledTestimonials className="bg--navy__extra_dark color--white">
      <IconBelly className="testimonials__belly d-none d-md-block" />
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10">
            <Slider
              className="testimonials__carousel"
              ref={(slider) => {
                ref.current = slider
              }}
              {...carouselSettings}
            >
              {testimonials.map((testimony) => (
                <div className="testimony text-center" key={testimony.quote}>
                  <div>
                    <GatsbyImage
                      image={getImage(testimony.logo)}
                      className="mb-4 mb-md-5"
                      alt=""
                    />
                  </div>

                  <p className="h1 mb-3 mb-md-4 font-weight--700">
                    {testimony.quote}
                  </p>

                  <div className="align-self-end">
                    <p className="h3 text--large mb-1 color--turquoise__primary">
                      <b>{testimony.author.name}</b>
                    </p>

                    <p className="text--large mb-1 color--turquoise__primary">
                      {testimony.author.role}
                    </p>

                    <p className="text--large color--silver__light">
                      {testimony.company}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="carousel__controls d-flex align-items-center justify-content-center mt-4 mt-md-5">
              <div className="controls__pictures d-flex align-items-center">
                {testimonials.map((testimony, index) => (
                  <button
                    type="button"
                    className={`bullet ${index === activeIndex && "active"}`}
                    onClick={() => goTo(index)}
                  >
                    <GatsbyImage
                      image={getImage(testimony.author.profilePicture)}
                      alt=""
                    />
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="testimonials__logos row">
          <p className="h2 color--silver__primary mb-4 text-center">
            Trusted by
          </p>
          {logos.map((logo) => (
            <div className="col-12 col-sm-6 col-md mb-4 mb-md-0" key={logo}>
              <div className="logo">
                <GatsbyImage image={getImage(logo)} alt="" />
              </div>
            </div>
          ))}
        </div>
      </Container>
    </StyledTestimonials>
  )
}

export default Testimo
