import React from "react"

// Libraries
import styled from "styled-components"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Lottie from "components/lottie/"

// Utils
import { colors } from "utils/variables"

// Animations
import AnimationTalentDiscovery from "assets/animations/home/how-it-works/talent-discovery-animation-1.json"
import AnimationRetentionPrediction from "assets/animations/home/how-it-works/retention-prediction-animation-1.json"
import AnimationBiasMitigation from "assets/animations/home/how-it-works/bias-mitigation-animation-1.json"
import TextLink from "components/text-link"

const StyledHowItWorks = styled.section`
  padding: 60px 0;
  border-top: 1px solid ${colors.silver__primary};
  background: ${colors.silver__pale};

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .title {
    margin-bottom: 40px;

    ${breakpoint.medium`
      margin-bottom: 56px;
    `}
  }

  .item {
    .item-wrapper {
      background: white;
      box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.2);
      border-radius: 20px;
      padding: 24px 32px 48px 32px;
      margin-bottom: 64px;

      ${breakpoint.small`
        margin-bottom: 128px;
      `}

      ${breakpoint.medium`
        padding: 56px 24px;
      `}
    }

    &:nth-child(even) {
      ${breakpoint.small`
        .content {
          order: 1;
        }
      `}
    }

    &:nth-child(odd) {
      ${breakpoint.small`
        .image {
          order: 1;
        }
      `}
    }

    .content {
      order: 0;
    }

    .image {
      order: 1;
      max-width: 543px;
    }
  }
`

const HowItWorks = () => {
  const data = [
    {
      title: "Talent Discovery",
      content:
        "Arena brings qualified candidates to hard-to-fill positions to augment your talent pool.",
      animation: AnimationTalentDiscovery,
      url: "/our-platform#talentDiscovery",
    },
    {
      title: "Retention Prediction",
      content:
        "We analyze 10K+ data points to generate a customized prediction per applicant, so you can hire with confidence.",
      animation: AnimationRetentionPrediction,
      url: "/our-platform#retentionPrediction",
    },
    {
      title: "Bias Mitigation",
      content:
        "Our adversarial fairness model identifies and removes potentially discriminatory data to reduce biased hiring.",
      animation: AnimationBiasMitigation,
      url: "/our-platform#biasMitigation",
    },
  ]

  return (
    <StyledHowItWorks className="bg--silver__pale">
      <Container>
        <h2 className="title h2 maxi text-center">How it Works</h2>

        <div className="row">
          {data.map((item) => (
            <div className="item col-12" key={item.title}>
              <div className="item-wrapper">
                <div className="row align-items-center justify-content-center">
                  <motion.div
                    initial={{ x: 64, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    className="image col-12 col-sm-6"
                  >
                    <Lottie animation={item.animation} />
                  </motion.div>

                  <motion.div
                    initial={{ x: -64, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5 }}
                    className="content col-12 col-sm-5 offset-sm-1 mb-4 mb-sm-0"
                  >
                    <div className="row">
                      <div className="col-12 col-sm-9">
                        <h2 className="h2 mb-2">{item.title}</h2>
                        <p className="text--normal mb-4 color--silver__dark">
                          {item.content}
                        </p>

                        <TextLink
                          to={item.url}
                          className="color--turquoise__primary color-hover--silver__dark svg--stroke-silver__primary svg-hover--stroke-silver__dark"
                        >
                          See how it works
                        </TextLink>
                      </div>
                    </div>
                  </motion.div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </StyledHowItWorks>
  )
}

export default HowItWorks
