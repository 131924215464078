import React from "react"

// Libraries
import styled from "styled-components"
// import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Button from "components/button"
import HeroArrow from "components/hero-arrow"
import Lottie from "components/lottie"

// Animations
import HeroAnimation from "assets/animations/home/hero-animation.json"

const StyledHero = styled.section`
  max-width: 100vw;
  min-height: calc(100vh - 62px - 58px);
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px 0;
  text-align: center;
  overflow: visible !important;

  ${breakpoint.small`
    min-height: calc(100vh - 96px - 58px);
  `}

  ${breakpoint.medium`
    text-align: left;
  `}

  ${Container} {
    position: relative;
    z-index: 2;
    overflow-x: hidden;
  }

  .hero__content {
    p {
      ${breakpoint.small`
        max-width: 87%;
      `}
    }
  }

  .hero__arrow {
    .arrow {
      background-color: ${colors.turquoise__pale} !important;

      &:hover {
        background-color: ${colors.turquoise__primary} !important;

        svg {
          * {
            stroke: ${colors.white} !important;
          }
        }
      }

      svg {
        * {
          stroke: ${colors.turquoise__primary} !important;
        }
      }
    }

    .arrow__illustration {
      * {
        fill: ${colors.white} !important;
      }
    }
  }
`

const Hero = () => (
  <StyledHero className="bg--white">
    <Container>
      <div className="row align-items-center">
        <div className="col-12 col-md-5 mb-4 mb-sm-0">
          <motion.div
            initial={{ y: 32, opacity: 0, scale: 0.99 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{
              duration: 0.3,
            }}
            className="hero__content"
          >
            <h1 className="h1 maxi mb-3">Transforming the Labor Market</h1>

            <p className="text--large mb-4">
              Arena Analytics helps organizations build more productive and
              equitable workforces, while empowering individuals to uncover and
              pursue opportunities where they are likely to thrive.
            </p>

            <Button type="link" level="primary" to="/about-us">
              Meet Arena Analytics
            </Button>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
          }}
          className="col-12 col-md-7"
        >
          <Lottie animation={HeroAnimation} />
          {/* <StaticImage src="../../assets/images/home/hero-image.png" alt="" /> */}
        </motion.div>
      </div>
    </Container>

    <HeroArrow className="hero__arrow" link="#analytics" />
  </StyledHero>
)

export default Hero
