import React from "react"

// Libraries
import styled from "styled-components"

// Sections
import Hero from "sections/home/hero" // 👈🏻 Delete this file to begin
import HowItWorks from "sections/home/how-it-works"
import Analytics from "sections/home/analytics"
import Testimonials from "sections/home/testimonials"
import Layout from "layouts/layout-primary"

const StyledHome = styled.div`
  > section {
    width: 100vw;
    overflow-x: hidden;
  }
`

const Home = () => (
  <Layout>
    <StyledHome>
      <Hero />
      <Analytics />
      <Testimonials />
      <HowItWorks />
    </StyledHome>
  </Layout>
)

export default Home
