import React from "react"

// Libraries
import styled from "styled-components"
import { motion } from "framer-motion"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import Button from "components/button"

// Icons
import IconBackground from "assets/icons/home/analytics/icon-analytics-background.inline.svg"
import IconCaretRight from "assets/icons/icon-caret-right.inline.svg"
import IconDiscoverTalent from "assets/icons/home/analytics/icon-discover-talent.inline.svg"
import IconRetentionPrediction from "assets/icons/home/analytics/icon-retention-prediction.inline.svg"
import IconBiasMitigation from "assets/icons/home/analytics/icon-bias-mitigation.inline.svg"

const StyledAnalytics = styled.section`
  position: relative;
  padding: 100px 0 60px 0;

  ${breakpoint.medium`
    padding: 200px 0 120px 0;
  `}

  ${Container} {
    position: relative;
    max-width: 1255px;
    z-index: 2;

    ${breakpoint.large`
      padding: 0 32px;
    `}

    ${breakpoint.extraLarge`
      padding: 0;
    `}
  }

  .card {
    position: relative;
    margin-bottom: 24px;
    padding: 24px;
    border-radius: 20px;
    box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.3);

    ${breakpoint.medium`
      padding: 72px;
    `}

    h3 {
      span {
        display: block;
      }
    }

    p {
      ${breakpoint.small`
        max-width: 370px;
        width: 80%;
      `}
    }

    .card__icon {
      display: flex;
      justify-content: flex-end;
      margin-right: -12px;
      margin-bottom: 32px;

      ${breakpoint.large`
        margin-right: -32px;
      `}

      svg {
        max-width: 100%;
        height: auto;
      }

      &.discover-talent {
        margin-top: -60px;

        ${breakpoint.large`
          margin-top: -120px;
        `}

        svg {
          width: 80%;
        }
      }

      &.retention-prediction {
        margin-top: -32px;

        ${breakpoint.large`
          margin-top: -80px;
        `}

        svg {
          width: 90%;
        }
      }

      &.bias-mitigation {
        margin-top: -40px;

        ${breakpoint.large`
          // margin-top: -80px;
        `}
      }
    }
  }

  .analytics__icon-background {
    max-width: 650px;
    width: 60%;
    position: absolute;
    right: 0;
    bottom: 24px;
    z-index: 1;

    ${breakpoint.medium`
      bottom: 60px;
    `}
  }
`

const Analytics = () => {
  const data = [
    {
      title: (
        <h3 className="h1">
          Talent
          <span className="color--turquoise__primary">Discovery</span>
        </h3>
      ),
      className: "discover-talent",
      description:
        "Our Talent Discovery solution uses predictive data modeling to optimize sourcing and recruitment marketing strategies, while supplying additional high-quality candidate volume where you need it the most.",
      url: "/our-platform#talentDiscovery",
      ctaLabel: "Grow your pipeline",
      icon: <IconDiscoverTalent />,
    },
    {
      title: (
        <h3 className="h1">
          Retention
          <span className="color--turquoise__primary">Prediction</span>
        </h3>
      ),
      className: "retention-prediction",
      description:
        "Our cutting-edge prediction model analyzes data about each candidate from a variety of sources to predict who is most likely to be retained by the organization if hired.",
      url: "/our-platform#retentionPrediction",
      ctaLabel: "Stop turnover",
      icon: <IconRetentionPrediction />,
    },
    {
      title: (
        <h3 className="h1">
          Bias
          <span className="color--turquoise__primary">Mitigation</span>
        </h3>
      ),
      className: "bias-mitigation",
      description:
        "Biased hiring keeps qualified candidates from positions where they would thrive. We embed bias mitigation tools at the core of our prediction to help build more diverse teams.",
      url: "/our-platform#biasMitigation",
      ctaLabel: "Diversify your staff",
      icon: <IconBiasMitigation />,
    },
  ]

  return (
    <StyledAnalytics className="bg--silver__pale" id="analytics">
      <Container>
        <div className="mb-4 mb-md-5 text-center">
          <h2 className="h2 maxi mb-3">
            Harness the Power of Predictive Analytics
          </h2>

          <div className="row justify-content-center">
            <div className="col-12 col-sm-8 col-md-6">
              <p className="text--large">
                The Arena Analytics platform uses cutting-edge predictive
                analytics and AI to help employers better discover and retain
                talent while mitigating bias in the hiring process.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          {/* Mobile column:start */}
          <div className="col-12 d-sm-none">
            <div className="row">
              {data.map((item) => (
                <div className="col-12" key={item.title}>
                  <motion.div
                    initial={{ y: 32, scale: 0.95 }}
                    whileInView={{ y: 0, scale: 1 }}
                    viewport={{ once: true }}
                    className="card bg--white"
                  >
                    {item.title}

                    <div className={`card__icon ${item.className}`}>
                      {item.icon}
                    </div>

                    <p className="mb-4 color--silver__dark">
                      {item.description}
                    </p>

                    <Button
                      type="link"
                      level="tertiary"
                      to={item.url}
                      className="w-100 d-inline-flex align-items-center svg-hover--stroke-navy__extra_dark"
                    >
                      {item.ctaLabel}
                      <IconCaretRight className="ms-2" />
                    </Button>
                  </motion.div>
                </div>
              ))}
            </div>
          </div>
          {/* Mobile column:end */}

          {/* Desktop column:start */}
          {/* Odd items:start */}
          <div className="d-none d-sm-block col-sm-6">
            {data.map(
              (item, index) =>
                (index + 1) % 2 !== 0 && (
                  <motion.div
                    initial={{ y: 32, scale: 0.95 }}
                    whileInView={{ y: 0, scale: 1 }}
                    viewport={{ once: true }}
                    className="card bg--white"
                    key={item.title}
                  >
                    {item.title}

                    <div className={`card__icon ${item.className}`}>
                      {item.icon}
                    </div>

                    <p className="mb-4 color--silver__dark">
                      {item.description}
                    </p>

                    <Button
                      type="link"
                      level="tertiary"
                      to={item.url}
                      className="d-inline-flex align-items-center svg-hover--stroke-navy__extra_dark"
                    >
                      {item.ctaLabel}
                      <IconCaretRight className="ms-2" />
                    </Button>
                  </motion.div>
                )
            )}
          </div>
          {/* Odd items:end */}

          {/* Even items:start */}
          <div className="d-none d-sm-block col-sm-6">
            {data.map(
              (item, index) =>
                (index + 1) % 2 === 0 && (
                  <motion.div
                    initial={{ y: 32, scale: 0.95 }}
                    whileInView={{ y: 0, scale: 1 }}
                    viewport={{ once: true }}
                    className="card bg--white"
                    key={item.title}
                  >
                    {item.title}

                    <div className={`card__icon ${item.className}`}>
                      {item.icon}
                    </div>

                    <p className="mb-4 color--silver__dark">
                      {item.description}
                    </p>

                    <Button
                      type="link"
                      level="tertiary"
                      to={item.url}
                      className="d-inline-flex align-items-center svg-hover--stroke-navy__extra_dark"
                    >
                      {item.ctaLabel}
                      <IconCaretRight className="ms-2" />
                    </Button>
                  </motion.div>
                )
            )}
          </div>
          {/* Even items:end */}
          {/* Desktop column:end */}
        </div>
      </Container>

      <IconBackground className="analytics__icon-background d-none d-sm-block" />
    </StyledAnalytics>
  )
}

export default Analytics
