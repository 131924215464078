import React from "react"

// Libraries
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// Icons
import IconCaret from "assets/icons/icon-caret-right.inline.svg"
import { fonts } from "utils/variables"

const TextLinkStyles = css`
  display: inline-flex;
  align-items: center;
  font-family: ${fonts.secondary};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4em;

  &:hover {
    svg {
      transform: translateX(8px);
    }
  }

  svg {
    transition: all 0.3s ease;
  }
`

const StyledTextLinkExternal = styled.a`
  ${TextLinkStyles};
`

const StyledTextLinkInternal = styled(Link)`
  ${TextLinkStyles};
`

const TextLink = (props) => {
  const { external, className, to, children } = props

  if (external) {
    return (
      <StyledTextLinkExternal
        href={to}
        className={className}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
        <IconCaret className="ms-2" />
      </StyledTextLinkExternal>
    )
  }

  return (
    <StyledTextLinkInternal to={to} className={className}>
      {children}
      <IconCaret className="ms-2" />
    </StyledTextLinkInternal>
  )
}

TextLink.propTypes = {
  external: PropTypes.bool,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
}

TextLink.defaultProps = {
  external: false,
  className: null,
}

export default TextLink
